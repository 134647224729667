import { Box, Text, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';

import { ButtonSubmit } from '../buttons/ButtonSubmit';
import { RegistrationInput } from '../inputs/RegistrationInput';

type CardDadosCadastraisProps = {
  textErrorMessage: string;
  value: any;
  setValue: (newState: any) => void;
  handleSubmit: () => Promise<void>;
  hrefNext: string;
};

export const CardDadosCadastrais = ({
  textErrorMessage,
  value,
  setValue,
  handleSubmit,
  hrefNext
}: CardDadosCadastraisProps) => {
  return (
    <>
      <VStack
        w="100%"
        maxW="600px"
        bg="#FFFFFF"
        boxShadow="0px 4px 10px rgba(50, 50, 50, 0.16);"
      >
        <Box
          w="100%"
          bg=" #001E64"
          p="16px 32px"
          display="flex"
          alignItems="center"
        >
          <Text
            fontSize={{
              base: '1rem',
              md: '1.25rem'
            }}
            fontWeight="700"
            lineHeight={{ base: '1.5rem', md: '1.75rem' }}
            color="#FFFFFF"
          >
            Por favor, informe seu CPF:
          </Text>
        </Box>
        <Box
          w="100%"
          m="0 !important"
          p={{ base: '10px 42px 25px', xl: '20px 74px 50px' }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gridGap={{ base: '25px', xl: '50px' }}
        >
          <RegistrationInput
            type={'text'}
            mask={'999.999.999-99'}
            placeholder={'CPF*'}
            textErrorMessage={textErrorMessage}
            displayTextLabel={false}
            value={value}
            setValue={setValue}
            necessarySubmit={true}
            handleSubmit={handleSubmit}
          />
          <ButtonSubmit
            hrefNext={hrefNext}
            textContent={'CONTINUAR'}
            reference={value}
            handleSubmit={handleSubmit}
          />
        </Box>
      </VStack>
    </>
  );
};

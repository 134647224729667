/* eslint-disable react/no-children-prop */
import {
  Box,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spinner,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react';
import {
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import { MdArrowDropDown } from 'react-icons/md';

type RegistrationSelectProps = {
  width?: string[];
  placeholder: string;
  displayTextLabel?: boolean;
  textLabel?: string;

  textErrorMessage: string;
  value: any;
  setValue?: any;
  options: {
    option: string;
    value?: string;
  }[];
  children?: ReactNode;
  disable?: boolean;
};

export const RegistrationDropdown = ({
  width,
  placeholder,
  displayTextLabel,
  textLabel,
  textErrorMessage,
  value,
  setValue,
  options,
  children,
  disable
}: RegistrationSelectProps) => {
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [showPlaceholderOverField, setShowPlaceholderOverField] = useState(
    Boolean(value) || disable
  );
  const [isListOpenState, setListOpenState] = useState(false);
  const defaultColor = '#FF5000';

  const getDescByVal = (value: string) => {
    return options.find((el) => el.value === value)?.option;
  };

  useEffect(() => {
    if (value) {
      setShowPlaceholderOverField(true);
    } else {
      setShowPlaceholderOverField(false);
    }
  }, [value]);

  return (
    <>
      <Stack w={width ? width : '100%'} m="0 !important" position="relative">
        {displayTextLabel && showPlaceholderOverField ? (
          <Text
            w={'100%'}
            fontSize={'12px'}
            m="0 !important"
            fontWeight="400"
            position="absolute"
            top="-8px"
            left="16px"
            zIndex="10"
            color="#828282"
            display={undefined}
          >
            {placeholder}
          </Text>
        ) : (
          <></>
        )}
        <InputGroup
          m="0 !important"
          w={['100%']}
          display="flex"
          flexDirection="column"
        >
          <Menu
            flip={false}
            closeOnSelect={true}
            closeOnBlur={true}
            colorScheme={'whiteAlpha'}
            offset={[0, -1]}
            matchWidth={true}
            onOpen={() => {
              setListOpenState(true);
              setShowPlaceholderOverField(true);
            }}
            onClose={() => {
              if (!value) {
                setDisplayErrorMessage(true);
              }
              setListOpenState(false);
            }}
          >
            <Box
              position="relative"
              pointerEvents={disable ? 'none' : undefined}
              m="0 !important"
              h="50px"
              bg="#FFFFFF"
              border="none"
              borderBottom={
                isListOpenState ? undefined : `1px solid #001E64 !important`
              }
              borderRadius="0 !important"
              _hover={
                isListOpenState
                  ? undefined
                  : defaultColor
                  ? { borderBottom: `1px solid ${defaultColor} !important` }
                  : { borderBottom: '1px solid #FF5000 !important' }
              }
              _focus={
                defaultColor
                  ? { borderBottom: `1px solid ${defaultColor} !important` }
                  : { borderBottom: '1px solid #FF5000 !important' }
              }
              transition="0.2s"
            >
              <MenuButton color={value ? '#000' : '#828282'} w="100%" h="100%">
                <Text
                  textAlign="start"
                  pl="15px"
                  fontSize="16px"
                  display={
                    showPlaceholderOverField && !value ? 'none' : 'block'
                  }
                >
                  {value ? getDescByVal(value) || value : placeholder}
                </Text>
              </MenuButton>

              <MenuList
                width="100%"
                borderRadius="4px"
                padding="0"
                maxHeight="263.94px"
                overflowY="scroll"
                sx={{
                  '&::-webkit-scrollbar': {
                    width: '10px'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    border: '1px solid #FFFFFF',
                    borderRadius: '6px',
                    backgroundColor: '#AAA'
                  }
                }}
                zIndex="100"
              >
                {options.map((el, i) => (
                  <MenuItem
                    key={i}
                    padding="10px 16px"
                    position="relative"
                    fontSize="16px"
                    onClick={() => {
                      console.log('setou', el.value);
                      setValue(el.value || el.option);
                    }}
                  >
                    {el.option}
                    {i !== options.length - 1 ? (
                      <Box
                        as={'span'}
                        width="90%"
                        borderBottom="1px solid #F4F4F4"
                        position="absolute"
                        bottom="0"
                      />
                    ) : (
                      <></>
                    )}
                  </MenuItem>
                ))}
              </MenuList>
            </Box>
          </Menu>
        </InputGroup>
        {displayErrorMessage && textErrorMessage && (
          <Text
            color="#AA2C23"
            fontSize="0.875rem"
            lineHeight="1.5rem"
            w="100%"
          >
            {textErrorMessage}
          </Text>
        )}
      </Stack>
    </>
  );
};

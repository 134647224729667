import { HStack, Stack } from '@chakra-ui/react';

import { ProductTypes } from '../../types';
import { getNumberFormat } from '../../utils/getNumberFormat';
import { RegistrationSelect } from '../inputs/RegistrationSelect';

type FormPixProps = {
  price: number;
  installments: string;
  productType: ProductTypes;
  setInstallments: (newState: string) => void;
  validity: string;
  setValidity: (newState: string) => void;
};

export const FormPix = ({
  price,
  installments,
  productType,
  setInstallments,
  validity,
  setValidity
}: FormPixProps) => {
  let installmentOptions = [
    {
      option: `Anual (1x ${getNumberFormat(price.toFixed(2))})`,
      rawInstallmentValue: price,
      value: `A`
    },
    {
      option: `Semestral (2x ${getNumberFormat((price / 2).toFixed(2))})`,
      rawInstallmentValue: price / 2,
      value: `S`
    },
    {
      option: `Trimestral (4x ${getNumberFormat((price / 4).toFixed(2))})`,
      rawInstallmentValue: price / 4,
      value: `T`
    }
  ];

  const optionsVencimento = Array.from(
    { length: 31 },
    (_, index) => index + 1
  ).map((el) => ({ option: String(el) }));

  if (
    productType === 'vida_flex' ||
    productType === 'vida_individual' ||
    productType === 'vida_mulher' ||
    productType === 'acidentes_pessoais'
  ) {
    installmentOptions.push({
      option: `Mensal (12x ${getNumberFormat((price / 12).toFixed(2))})`,
      rawInstallmentValue: price / 12,
      value: `M`
    });
  }

  installmentOptions = installmentOptions.filter((installmentOption) => {
    return installmentOption.rawInstallmentValue >= 10;
  });

  return (
    <Stack
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      p={['40px 20px', '40px 20px', '30px 50px 20px 30px']}
      bg="#FFFFFF"
      m="5px 0 0 0 !important"
      maxW="850px"
      w="100%"
      borderRadius="0 !important"
    >
      <HStack
        p="0 0 20px"
        m="0 !important"
        w="100%"
        display="flex"
        gap={['0px', '0px', '30px']}
        flexDirection={['column', 'column', 'row']}
      >
        <RegistrationSelect
          width={['100%', '100%', '50%']}
          placeholder={'Data do Vencimento*'}
          textLabel={validity ? 'Data do Vencimento*' : ''}
          textErrorMessage={validity === '' ? 'Campo Obrigatório' : ''}
          value={validity}
          setValue={setValidity}
          options={optionsVencimento}
        />
        <RegistrationSelect
          placeholder={'Parcelamento*'}
          textLabel={installments ? 'Parcelamento*' : ''}
          textErrorMessage={installments === '' ? 'Campo Obrigatório' : ''}
          value={installments}
          setValue={setInstallments}
          options={installmentOptions}
        />
      </HStack>
    </Stack>
  );
};

import axios from 'axios';

import { customLocalStorage } from '../utils/storage.util';

const defaultHeaders = {
  'Access-Control-Allow-Origin': '*',
  Authorization: `Basic ${btoa(
    `${process.env.REACT_APP_WL_CLIENT_ID}:${process.env.REACT_APP_WL_CLIENT_SECRET}`
  )}`
};

export const apiSensedia = async () => {
  if (process.env.REACT_APP_NODE_ENV != 'development') {
    if (!customLocalStorage.getItem('accessTokenBFF')) {
      console.log('gerando novo token');
      const accessTokenResponse = await axios.post(
        `${process.env.REACT_APP_SENSEDIA_OAUTH_API}/access-token`,
        {
          grant_type: 'client_credentials'
        },
        {
          headers: { ...defaultHeaders }
        }
      );

      const accessToken = accessTokenResponse.data.access_token;

      customLocalStorage.setItem('accessTokenBFF', accessToken, 3300000); //55 minutos

      console.log(process.env.REACT_APP_BFF_URL);
      return axios.create({
        baseURL: process.env.REACT_APP_BFF_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      });
    } else {
      console.log('access token guardado na memora', customLocalStorage.getItem('accessTokenBFF'));
      const accessToken = customLocalStorage.getItem('accessTokenBFF');
      return axios.create({
        baseURL: process.env.REACT_APP_BFF_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      });
    }
  } else {
    return axios.create({
      baseURL: process.env.REACT_APP_BFF_URL
    });
  }
};

import { ProductTypes } from '../types';
import { isJson } from './json.util';
import { wlLocalStorage } from './localStorage.util';

export const isCoberturaMortePrincipal = (idCobertura: number) => {
  const idsCoberturasMorte =
    process.env.REACT_APP_NODE_ENV == 'production'
      ? [6994880491, 1181397588, 1181398443, 7986106965]
      : [6314897603, 6994880491, 1181397588, 1181398443, 40529084995];

  return (
    idsCoberturasMorte.filter((id) => {
      if (id == idCobertura) return true;
    }).length == 1
  );
};

export const isCoberturaMorteAcidental = (idCobertura: number) => {
  const idsCoberturasMorte =
    process.env.REACT_APP_NODE_ENV == 'production'
      ? [1181397587, 1181398442, 7986106961]
      : [1181397587, 1181398442, 40529084991];

  return (
    idsCoberturasMorte.filter((id) => {
      if (id == idCobertura) return true;
    }).length == 1
  );
};

export const isCoberturaDIT = (idCobertura: number) => {
  const idsCoberturasMorte =
    process.env.REACT_APP_NODE_ENV == 'production'
      ? [3762773140, 3762773141, 7986110518]
      : [3762773140, 3762773141, 40529089519];

  return (
    idsCoberturasMorte.filter((id) => {
      if (id == idCobertura) return true;
    }).length == 1
  );
};

export const getProductType: (
  idProduto: number
) => ProductTypes = (
  idProduto: number
) => {
    const productsMap: any =
      process.env.REACT_APP_NODE_ENV == 'production'
        ? {
          6994880490: 'acidentes_pessoais',
          1181397586: 'vida_individual',
          1181398442: 'vida_mulher',
          7986106960: 'vida_flex'
        }
        : {
          6314897602: 'acidentes_pessoais',
          6994880490: 'acidentes_pessoais',
          1181397586: 'vida_individual',
          1181398441: 'vida_mulher',
          40529084990: 'vida_flex'
        };

    return productsMap[idProduto];
  };

export const getPlanId = (idProduto: number) => {
  const planId =
    process.env.REACT_APP_NODE_ENV === 'production'
      ?
      [
        { plan: 7986135400, product: 'vida_flex' },
        { plan: 1181398440, product: 'vida_individual' },
        { plan: 8575712351, product: 'acidentes_pessoais' }
      ] :
      [
        { plan: 40529107369, product: 'vida_flex' },
        { plan: 1181398440, product: 'vida_individual' },
        { plan: 40529794768, product: 'acidentes_pessoais' },
      ];

  const response = planId.filter((plan) =>
    getProductType(idProduto) === plan.product ? plan.plan : null
  );

  return response.find((item) => item.plan)?.plan;
};

export const hasDit = () => Boolean(
  isJson(wlLocalStorage.getItem('quotationData') || '') &&
    Boolean(JSON.parse(wlLocalStorage.getItem('quotationData') || '{}').coberturas)
    ? (() => {
      const coversIds = (
        JSON.parse(wlLocalStorage.getItem('quotationData') || '[]').coberturas as []
      )?.map((el: any) => el.id_cobertura);

      for (let i = 0; i < coversIds.length; i++) {
        if (isCoberturaDIT(coversIds[i])) {
          return true;
        }
      }
    })()
    : false
);
export const MAX_NUMBER_COVERAGES = 5;

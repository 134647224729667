import { Box, Image, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import woman_welcome from '../assets/images/woman-welcome.png';
import { CardDadosCadastrais } from '../components/cards/CardDadosCadastrais';
import { ConsentTerm } from '../components/fragments/ConsentTerm';
import { Header } from '../components/layouts/Header';
import { wlLocalStorage } from '../utils/localStorage.util';

const HEADER_HEIGHT = '112px';

export const DadosCadastraisInicio = () => {
  const [cpf, setCpf] = useState(wlLocalStorage.getItem('cpf') || '');
  const [returnReqCpf, setReturnReqCpf] = useState('');
  const [isDisableBtn, setIsDisableBtn] = useState(true);
  const [userId, setUserId] = useState('00');

  const getCpf = async () => {
    const reviewCpf = cpf.replaceAll('_', '').replaceAll('.', '');

    if (reviewCpf != '' && reviewCpf.length === 11) {
      console.log('reviewCpf', reviewCpf);
      wlLocalStorage.setItem('cpf', reviewCpf);
      setIsDisableBtn(false);
      try {
        setUserId('01');
      } catch (error) {
        setUserId('00');
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (cpf.replaceAll('_', '').replaceAll('.', '') === '') {
      setReturnReqCpf('Campo obrigatório');
    } else if (cpf.replaceAll('_', '').replaceAll('.', '').length < 11) {
      setReturnReqCpf('CPF inválido');
    } else {
      setReturnReqCpf('');
    }
  }, [cpf]);

  return (
    <>
      <Header />
      <Box
        w="100%"
        h={`calc(100vh - ${HEADER_HEIGHT})`}
        m="0 !important"
        p="0 16px"
        display="flex"
        justifyContent="center"
      >
        <Box
          w="100%"
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
          p={['36px 0 20px', '50px 64px 0 124px ']}
        >
          <Text
            w="100%"
            textAlign={['center', 'start']}
            fontSize={['1.5rem', '2.5rem']}
            fontWeight="700"
            lineHeight={['2rem', '3rem']}
            color="#323232"
          >
            Olá, bem-vindo a SulAmérica!
          </Text>
          <Text
            w="100%"
            textAlign={['center', 'start']}
            fontSize={['1rem', '1.25rem']}
            fontWeight="400"
            lineHeight={['1.5rem', '1.75rem']}
            color="#323232"
            m={['20px 0 36px', '16px 0 70px']}
          >
            Precisamos de alguns dados para seguir.
          </Text>

          <CardDadosCadastrais
            textErrorMessage={returnReqCpf}
            handleSubmit={getCpf}
            value={cpf}
            setValue={setCpf}
            hrefNext={`/dados-cadastrais/formularios/product=${window.location.pathname.replace(
              /[^0-9,]/gi,
              ''
            )}&cpf=${cpf
              .replaceAll('.', '')
              .replaceAll('-', '')}&user=${userId}`}
          />
          <Box m="15px 0 0 0 !important" w="100%" maxW="600px">
            <ConsentTerm />
          </Box>
        </Box>
        <Image
          display={['none', 'flex']}
          objectFit="contain"
          src={woman_welcome}
          boxSize="100%"
          pt="30px"
        />
      </Box>
    </>
  );
};

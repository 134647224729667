import { HStack, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';

import { ProductTypes } from '../../types';
import { getNumberFormat } from '../../utils/getNumberFormat';
import {
  getInstallmentOver10,
  getInstallmentsNumberByChar
} from '../../utils/money.util';
import { getProductType } from '../../utils/products.utils';
import { FormCartaoCredito } from '../forms/FormCartaoCredito';
import { FormDebitoAutomatico } from '../forms/FormDebitoAutomatico';
import { FormPix } from '../forms/FormPix';

type CardPagamentoFormPaymentProps = {
  recomended: boolean;
  price: number;
  fullPrice: number;
  promoPrice: number;
  value: string;
  setValue: (newState: string) => void;
  isHolder: boolean;
  setIsHolder: (newState: boolean) => void;
  cpf: string;
  setCpf: (newState: string) => void;
  installments: string;
  productType: ProductTypes;
  setInstallments: (newState: string) => void;
  numberCardCredit: string;
  setNumberCardCredit: (newState: string) => void;
  validity: string;
  setValidity: (newState: string) => void;
  nameCardCredit: string;
  setNameCardCredit: (newState: string) => void;
  bank: string;
  setBank: (newState: string) => void;
  bankAgency: string;
  setBankAgency: (newState: string) => void;
  bankAccount: string;
  setBankAccount: (newState: string) => void;
  verifyingDigit: string;
  setVerifyingDigit: (newState: string) => void;
  dadosCampanha: any;
};

export const CardPagamentoFormPayment = ({
  recomended,
  price,
  fullPrice,
  promoPrice,
  value,
  setValue,
  isHolder,
  setIsHolder,
  cpf,
  setCpf,
  installments,
  productType,
  setInstallments,
  numberCardCredit,
  setNumberCardCredit,
  validity,
  setValidity,
  nameCardCredit,
  setNameCardCredit,
  bank,
  setBank,
  bankAgency,
  setBankAgency,
  bankAccount,
  setBankAccount,
  verifyingDigit,
  setVerifyingDigit,
  dadosCampanha
}: CardPagamentoFormPaymentProps) => {
  const paymentMethodHasDisccount = (method: 'CAR' | 'DCC' | 'PIX') => {
    return dadosCampanha?.vias_pagamento?.includes(method);
  };

  const flagDesconto = (method: string) =>
    paymentMethodHasDisccount(method as any) && (
      <Text
        p="0 15px"
        bg={'#FF5000'}
        color="#FFFFFF"
        fontSize="0.75rem"
        lineHeight="1.25rem"
        fontWeight="700"
        position="absolute"
        top="0"
        right="0"
        borderRadius="4px 4px 0px 4px"
      >
        {dadosCampanha?.percentual_desconto}% de desconto
      </Text>
    );

  const flagPreco = (method: string) => (
    <Text
      color="#323232"
      fontSize="1rem"
      lineHeight="1.25rem"
      fontWeight="700"
      position="absolute"
      top="32px"
      right="30px"
      borderRadius="4px 4px 0px 4px"
    >
      {paymentMethodHasDisccount(method as any)
        ? installments
          ? getNumberFormat(
              (
                promoPrice / getInstallmentsNumberByChar(installments as any)
              ).toFixed(2)
            )
          : getNumberFormat(
              getInstallmentOver10(promoPrice).installment.toFixed(2)
            )
        : installments
        ? getNumberFormat(
            (
              fullPrice / getInstallmentsNumberByChar(installments as any)
            ).toFixed(2)
          )
        : getNumberFormat(
            getInstallmentOver10(fullPrice).installment.toFixed(2)
          )}
    </Text>
  );

  return (
    <>
      <RadioGroup onChange={setValue} value={value} maxW="850px" w="100%">
        <HStack
          m="20px 0 0 0 !important"
          bg="#FFFFFF"
          p="30px"
          boxShadow=" 0px 4px 4px rgba(0, 0, 0, 0.25)"
          position="relative"
          border={value === 'credito' ? `1px solid #FF5000` : ''}
          borderRadius="4px"
        >
          <Radio
            colorScheme="orange"
            value="credito"
            name="FormPayment"
            w="100%"
            onClick={() => {
              setCpf('');
              setInstallments('');
              setNumberCardCredit('');
              setValidity('');
              setNameCardCredit('');
            }}
          >
            <Text
              w="100%"
              m="0 !important"
              color={value === 'credito' ? '#FF5000' : '#323232'}
              fontSize={['0.875rem', '0.875rem', '1rem']}
              lineHeight={['1.25rem', '1.25rem', '1.5rem']}
              fontWeight="700"
            >
              Cartão de Crédito
            </Text>
          </Radio>
          {flagDesconto('CAR')}
          {flagPreco('CAR')}
        </HStack>
        <Stack w="100%">
          {value === 'credito' && (
            <FormCartaoCredito
              price={price}
              isHolder={isHolder}
              setIsHolder={setIsHolder}
              cpf={cpf}
              setCpf={setCpf}
              installments={installments}
              setInstallments={setInstallments}
              numberCardCredit={numberCardCredit}
              setNumberCardCredit={setNumberCardCredit}
              validity={validity}
              setValidity={setValidity}
              nameCardCredit={nameCardCredit}
              setNameCardCredit={setNameCardCredit}
              productType={productType}
            />
          )}
        </Stack>
        <HStack
          m="20px 0 0 0 !important"
          bg="#FFFFFF"
          p="30px"
          boxShadow=" 0px 4px 4px rgba(0, 0, 0, 0.25)"
          border={value === 'debito' ? `1px solid #FF5000` : ''}
          position="relative"
          borderRadius="4px"
        >
          <Radio
            colorScheme="orange"
            value="debito"
            name="FormPayment"
            w="100%"
            onClick={() => {
              setCpf('');
              setInstallments('');
              setValidity('');
              setBank('');
              setBankAgency('');
              setBankAccount('');
              setVerifyingDigit('');
            }}
          >
            <Text
              w="100%"
              m="0 !important"
              color={value === 'debito' ? '#FF5000' : '#323232'}
              fontSize={['0.875rem', '0.875rem', '1rem']}
              lineHeight={['1.25rem', '1.25rem', '1.5rem']}
              fontWeight="700"
            >
              Débito Automático
            </Text>
          </Radio>
          {flagDesconto('DCC')}
          {flagPreco('DCC')}
        </HStack>
        <Stack w="100%">
          {value === 'debito' && (
            <FormDebitoAutomatico
              price={price}
              cpf={cpf}
              setCpf={setCpf}
              installments={installments}
              setInstallments={setInstallments}
              validity={validity}
              setValidity={setValidity}
              isHolder={isHolder}
              setIsHolder={setIsHolder}
              bank={bank}
              setBank={setBank}
              bankAgency={bankAgency}
              setBankAgency={setBankAgency}
              bankAccount={bankAccount}
              setBankAccount={setBankAccount}
              verifyingDigit={verifyingDigit}
              setVerifyingDigit={setVerifyingDigit}
              productType={productType}
            />
          )}
        </Stack>
        <HStack
          m="20px 0 0 0 !important"
          bg="#FFFFFF"
          p="30px"
          boxShadow=" 0px 4px 4px rgba(0, 0, 0, 0.25)"
          border={value === 'pix' ? `1px solid #FF5000` : ''}
          borderRadius="4px"
          position="relative"
        >
          <Radio
            colorScheme="orange"
            value="pix"
            name="FormPayment"
            w="100%"
            onClick={() => {
              setInstallments('');
              setValidity('');
            }}
          >
            <Text
              w="100%"
              m="0 !important"
              color={value === 'pix' ? '#FF5000' : '#323232'}
              fontSize={['0.875rem', '0.875rem', '1rem']}
              lineHeight={['1.25rem', '1.25rem', '1.5rem']}
              fontWeight="700"
            >
              PIX
            </Text>
          </Radio>
          {flagDesconto('PIX')}
          {flagPreco('PIX')}
        </HStack>
        <Stack w="100%">
          {value === 'pix' && (
            <FormPix
              price={price}
              installments={installments}
              setInstallments={setInstallments}
              validity={validity}
              setValidity={setValidity}
              productType={productType}
            />
          )}
        </Stack>
      </RadioGroup>
    </>
  );
};

import { isJson } from "./json.util";

export const customLocalStorage = {
  getItem(key: string) {
    console.log('debug token', localStorage.getItem(key));
    if (!isJson(localStorage.getItem(key) || '')) {
      localStorage.removeItem(key);
    }
    if (localStorage.getItem(key)) {
      const currentDateInMillis = +new Date();

      const storagePayload = JSON.parse(localStorage.getItem(key) || '{}');

      const expiry = storagePayload.expiry;
      const pastDateInMillis = storagePayload.currentDateInMillis;
      const timeLeftInMillis = currentDateInMillis - pastDateInMillis;

      console.log(`token gerado há ${(timeLeftInMillis / (1000 * 60)).toFixed()} minutos`)

      if (timeLeftInMillis >= expiry) {
        localStorage.removeItem(key);
      }
    }

    return JSON.parse(localStorage.getItem(key) || '{}')?.value || null;
  },
  setItem(key: string, value: any, expiry: number) {
    if (expiry === 0) return
    const storagePayload = { value: value, expiry: expiry, currentDateInMillis: +new Date() }
    console.log('setando token no storage', JSON.stringify(storagePayload))
    localStorage.setItem(key, JSON.stringify(storagePayload));
  },
  removeItem(key: any) {
    localStorage.removeItem(key)
  },
  clear() {
    localStorage.clear()
  }
}
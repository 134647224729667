import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react';

import chipImage from '../../assets/images/chipImage.png';
import logoCardCreditImage from '../../assets/images/logoCardCreditImage.png';
import { ProductTypes } from '../../types';
import { getNumberFormat } from '../../utils/getNumberFormat';
import { RegistrationInput } from '../inputs/RegistrationInput';
import { RegistrationSelect } from '../inputs/RegistrationSelect';

type FormCartaoCreditoProps = {
  price: number;
  isHolder: boolean;
  setIsHolder: (newState: boolean) => void;
  cpf: string;
  setCpf: (newState: string) => void;
  installments: string;
  productType: ProductTypes;
  setInstallments: (newState: string) => void;
  numberCardCredit: string;
  setNumberCardCredit: (newState: string) => void;
  validity: string;
  setValidity: (newState: string) => void;
  nameCardCredit: string;
  setNameCardCredit: (newState: string) => void;
};

export const FormCartaoCredito = ({
  price,
  cpf,
  setCpf,
  installments,
  productType,
  setInstallments,
  numberCardCredit,
  setNumberCardCredit,
  validity,
  setValidity,
  nameCardCredit,
  setNameCardCredit
}: FormCartaoCreditoProps) => {
  const formatedValidity = validity.replace('_', '.').split('/');

  const installmentOptions = [
    {
      option: `Anual (1x ${getNumberFormat(price.toFixed(2))})`,
      rawInstallmentValue: price,
      value: `A`
    },
    {
      option: `Semestral (2x ${getNumberFormat((price / 2).toFixed(2))})`,
      rawInstallmentValue: price / 2,
      value: `S`
    },
    {
      option: `Trimestral (4x ${getNumberFormat((price / 4).toFixed(2))})`,
      rawInstallmentValue: price / 4,
      value: `T`
    },
    {
      option: `Mensal (12x ${getNumberFormat((price / 12).toFixed(2))})`,
      rawInstallmentValue: price / 12,
      value: `M`
    }
  ].filter((installmentOption) => {
    return installmentOption.rawInstallmentValue >= 10;
  });

  return (
    <Stack
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      p={['40px 20px', '40px 20px', '30px 50px 30px 30px']}
      bg="#FFFFFF"
      m={['0 !important', '0 !important', '5px 0 0 0 !important']}
      maxW="850px"
      w="100%"
    >
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        gap="28px"
      >
        <Box
          maxW={['273px', '273px', '444.51px']}
          w="100%"
          h={['162px', '162px', '258px']}
          bg="#2A4E9A"
          borderRadius="16px"
          position="relative"
        >
          <Image
            src={chipImage}
            position="absolute"
            top={['15px', '15px', '30px']}
            left={['15px', '15px', '30px']}
            w={['38.75px', '38.75px', '63px']}
            h={['32px', '32px', '51px']}
          />
          <Text
            maxW="300px"
            w="85%"
            color="#FFFFFF"
            fontSize={['0.875rem', '0.875rem', '1.75rem']}
            lineHeight={['1.25rem', '1.25rem', '2rem']}
            fontWeight="400"
            position="absolute"
            top={['80px', '80px', '120px']}
            left={['15px', '15px', '30px']}
          >
            {numberCardCredit === ''
              ? '0000 0000 0000 0000'
              : numberCardCredit.replaceAll('_', '').replaceAll('.', ' ')}
          </Text>
          <Text
            color="#FFFFFF"
            fontSize="0.75rem"
            lineHeight="1.25rem"
            fontWeight="400"
            position="absolute"
            top={['102px', '102px', '160px']}
            right={['15px', '15px', '320px']}
            display="flex"
            flexDirection={['column', 'column', 'row']}
            alignItems="center"
          >
            <span>Validade:&nbsp;</span>
            {formatedValidity.toString() === ''
              ? '__/__'
              : formatedValidity.toString().replace(',', '/')}
          </Text>
          <Text
            maxW="300px"
            w={['50%', '50%', '85%']}
            color="#FFFFFF"
            fontSize={['0.875rem', '0.875rem', '1.75rem']}
            lineHeight={['1.25rem', '1.25rem', '2rem']}
            fontWeight="400"
            position="absolute"
            left={['15px', '15px', '30px']}
            top={['122px', '122px', '200px']}
            h={['20px', '20px', '30px']}
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {nameCardCredit === ''
              ? 'NOME DO CARTÃO'
              : nameCardCredit.toLocaleUpperCase()}
          </Text>
          <Image
            display={['none', 'none', 'block']}
            src={logoCardCreditImage}
            position="absolute"
            bottom={['15px', '15px', '30px']}
            right={['15px', '15px', '30px']}
            w="45px"
            h="30px"
          />
        </Box>
      </Box>
      <HStack
        p="30px 0"
        m="0 !important"
        w="100%"
        display="flex"
        gap={['0px', '0px', '30px']}
        flexDirection={['column', 'column', 'row']}
      >
        <RegistrationInput
          type={'text'}
          mask={'999.999.999.99'}
          placeholder={'CPF*'}
          textLabel={cpf ? 'CPF*' : ''}
          textErrorMessage={
            cpf === ''
              ? 'Campo Obrigatório'
              : cpf.replaceAll('_', '').replaceAll('.', '').length < 11
              ? 'CPF inválido'
              : ''
          }
          value={cpf}
          setValue={setCpf}
        />
        <RegistrationInput
          type={'text'}
          mask={'9999.9999.9999.9999'}
          placeholder={'Número do cartão*'}
          textLabel={numberCardCredit ? 'Número do cartão*' : ''}
          textErrorMessage={
            numberCardCredit.replaceAll('.', '') === ''
              ? 'Campo Obrigatório'
              : numberCardCredit.replaceAll('_', '').length < 19
              ? 'Cartão inválido'
              : ''
          }
          value={numberCardCredit.replace(/[^0-9,]/gi, '')}
          setValue={setNumberCardCredit}
        />
        <RegistrationInput
          width={['100%', '100%', '50%']}
          type={'text'}
          mask={'99/99'}
          placeholder={'Validade*'}
          textLabel={validity ? 'Validade*' : ''}
          textErrorMessage={
            validity.replaceAll('_', '').replaceAll('/', '') === ''
              ? 'Campo Obrigatório'
              : validity.replaceAll('_', '').replaceAll('/', '').length < 4 ||
                parseInt(validity.replace('_', '.').split('/')[0]) > 12 ||
                parseInt(validity.replace('_', '.').split('/')[1]) < 22
              ? 'Validade inválido'
              : ''
          }
          value={validity}
          setValue={setValidity}
        />
      </HStack>
      <HStack
        p="0 0 20px"
        m="0 !important"
        w="100%"
        display="flex"
        gap={['0px', '0px', '30px']}
        flexDirection={['column', 'column', 'row']}
      >
        <RegistrationInput
          type={'text'}
          mask={''}
          placeholder={'Nome do cartão*'}
          textLabel={nameCardCredit ? 'Nome do cartão*' : ''}
          textErrorMessage={nameCardCredit === '' ? 'Campo Obrigatório' : ''}
          value={nameCardCredit.replace(
            /[/.,~!@#$%¨*()=+:;?&_0-9-[\]<>'"|]/g,
            ''
          )}
          setValue={setNameCardCredit}
        />
        <RegistrationSelect
          placeholder={'Parcelamento*'}
          textLabel={installments ? 'Parcelamento*' : ''}
          textErrorMessage={installments === '' ? 'Campo Obrigatório' : ''}
          value={installments}
          setValue={setInstallments}
          options={installmentOptions}
          displayTextLabel={false}
        />
      </HStack>
    </Stack>
  );
};

import { Box, HStack, Link, Text, useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';

import { apiSensedia } from '../api/api-sensedia';
import { AcidentesPessoaisIcon } from '../assets/icons/produtos/AcidentesPessoaisIcon';
import { VidaIndividualIcon } from '../assets/icons/produtos/VidaIndividualIcon';
import { CardCheckout } from '../components/cards/CardCheckout';
import { SectionResumo } from '../components/contents/SectionResumo';
import { SliderResumo } from '../components/contents/SliderResumo';
import { DropdownCheckout } from '../components/dropdowns/DropdownCheckout';
import { BuyConfirmation } from '../components/fragments/BuyConfirmation';
import { LayoutContent } from '../components/layouts/LayoutContent';
import { ModalLoad } from '../components/modals/ModalLoad';
import { mockedAdvantage } from '../mocks/mockedAdvantage';
import { RegistroVendaProps } from '../types';
import { getHiddenAssistsIds } from '../utils/general.util';
import { wlLocalStorage } from '../utils/localStorage.util';
import {
  getInstallmentOver10,
  getMonthNameByChar,
  PaymentFrequencyType
} from '../utils/money.util';
import { occupationList } from '../utils/occupations';
import {
  getPlanId,
  getProductType,
  hasDit,
  isCoberturaDIT
} from '../utils/products.utils';

const ALLOWED_VALUES = [
  10000, 20000, 30000, 40000, 50000, 70000, 100000, 150000, 200000, 250000,
  300000, 400000, 450000
];

export const Resumo = () => {
  const coberturasBasicasFirstRenderRef = useRef(true);
  const lastCoversSwitchesStateValue = useRef({});
  const lastAssistsSwitchesStateValue = useRef({});
  const preventNextExecution = useRef(false);

  const [editableQuotationData, setEditableQuotationData] = useState<any>({});
  const [initialFilteredDataState, setInitialFilteredDataState] = useState<any>(
    {}
  );
  const advantages = mockedAdvantage;

  const cardCheckoutRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const toast = useToast();

  const [productSelected, setProductSelected] = useState({
    title: '',
    subtitle: '',
    text: '',
    price: 0,
    price_desconto_campanha: 0,
    assistences: [
      {
        assist: ''
      },
      {
        assist: ''
      },
      {
        assist: ''
      }
    ],
    covers: [
      {
        cover: '',
        value: ''
      },
      {
        cover: '',
        value: ''
      }
    ],
    recommended: false,
    id: ''
  });

  const [coberturasRetornadasDaCotacao, setCoberturasRetornadasDaCotacao] =
    useState<any[]>([]);

  const [assistenciasRetornadasDaCotacao, setAssistenciasRetornadasDaCotacao] =
    useState<any[]>([]);

  const [initialCovers, setInitialCovers] = useState<any[]>([]);
  const [initialAssists, setInitialAssists] = useState<any[]>([]);

  const [assistsSwitchesState, setAssistsSwitchesState] = useState<any>({});
  const [coversSwitchesState, setCoversSwitchesState] = useState<any>({});
  const [coversModalEditionState, setCoversModalEditionState] = useState<any>(
    {}
  );

  const [openModal, setOpenModal] = useState(false);
  const [sliderValue, setSliderValue] = useState(100000);

  const [isBtnContratarDisabled, setBtnContratarDisabled] = useState(false);

  const [campanhaDescontoBody, setCampanhaDescontoBody] = useState<any>({
    campanha: null
  });

  const [coberturasProduto, setCoberturasProduto] = useState<any[]>([]);

  const query = location.pathname;
  const parts = query.split('&');
  const idProduto = Number(parts[0].replace(/[^0-9,]/gi, ''));
  const quotationData = wlLocalStorage.getItem('quotationData') || '{}';

  const parsedQuotationData = JSON.parse(quotationData);

  const produtoSelecionadoNaHome = JSON.parse(
    wlLocalStorage.getItem('selectedProduct') || '{}'
  );
  const birthDate = wlLocalStorage.getItem('date') || '';
  const genre = wlLocalStorage.getItem('genre') || '';
  const brokerage = wlLocalStorage.getItem('brokerage');
  const agency = wlLocalStorage.getItem('agency');
  const occupation = wlLocalStorage.getItem('occupationSelected') || '';
  const priceSelected = Number(wlLocalStorage.getItem('priceSelected'));
  const cpf = wlLocalStorage.getItem('cpf');
  const valorRenda = Number(wlLocalStorage.getItem('valor_renda'));
  const tipoCategoriaProfissional = wlLocalStorage.getItem(
    'tipoCategoriaProfissional'
  );
  const tipoFranquia = wlLocalStorage.getItem('tipoFranquia');
  const commission =
    wlLocalStorage.getItem('commission') === 'undefined'
      ? undefined
      : wlLocalStorage.getItem('commission');

  const isProductVariable = () => {
    return (
      (Boolean(produtoSelecionadoNaHome?.minValue) &&
        Boolean(produtoSelecionadoNaHome?.maxValue)) ||
      produtoSelecionadoNaHome.possui_capital_variavel
    );
  };

  const coversToSend = parsedQuotationData.coberturas.map(
    (cover: { valor_capital_informado: any }) => {
      return {
        ...cover,
        valor_capital_informado: cover.valor_capital_informado
      };
    }
  );

  const title = wlLocalStorage.getItem('title') || '';
  const subtitle = wlLocalStorage.getItem('subtitle') || '';
  const text = wlLocalStorage.getItem('text') || '';
  const assist = wlLocalStorage.getItem('assist') || '{}';
  const parsedAssist = JSON.parse(assist);

  const name = wlLocalStorage.getItem('name') || '';
  const socialName = wlLocalStorage.getItem('socialName');
  const phone = wlLocalStorage.getItem('phone') || '';
  const email = wlLocalStorage.getItem('email') || '';
  const codigo_estrutura_venda =
    wlLocalStorage.getItem('codigo_estrutura_venda') || '';
  const codigo_corretor = wlLocalStorage.getItem('codigo_corretor') || '';

  const idWebsite = wlLocalStorage.getItem('id_website') || '';
  const idProdutoCustomizado =
    wlLocalStorage.getItem('idProdutoCustomizado') || '';

  let idAcesso: string | undefined = undefined;

  try {
    idAcesso = JSON.parse(sessionStorage.getItem('currentAccess') || '{}').id;
  } catch (error) {
    console.log(error);
  }

  const coberturasRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const assistenciasRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const getDataProductSelected = async ({
    quotationData,
    initialQuotationData,
    chamadaConsiderandoProfissao,
    mapaValoresMaximosCoberturas
  }: {
    quotationData?: any;
    initialQuotationData?: any;
    chamadaConsiderandoProfissao?: boolean;
    mapaValoresMaximosCoberturas?: Record<string, number>;
  }) => {
    if (!initialQuotationData) {
      initialQuotationData = JSON.parse(JSON.stringify(parsedQuotationData));
    }

    setOpenModal(true);
    try {
      if (!quotationData) {
        setEditableQuotationData(initialQuotationData);
      }

      if (chamadaConsiderandoProfissao) {
        setEditableQuotationData(initialQuotationData || quotationData);
      }

      let baseData = {
        ...initialQuotationData,
        valor_renda: valorRenda,
        codigo_parceiro: 3033856,
        data_nascimento: birthDate,
        sexo: genre[0],
        percentual_corretagem: brokerage ? Number(brokerage) : 2,
        percentual_agenciamento: agency ? Number(agency) : 100,
        atividade_profissional: occupation || '111',
        coberturas: initialQuotationData.coberturas,
        tipo_categoria_profissional: hasDit()
          ? tipoCategoriaProfissional ?? undefined
          : undefined,
        tipo_franquia: hasDit() ? tipoFranquia ?? undefined : undefined
      };

      let data = {
        ...(quotationData || initialQuotationData),
        valor_renda: valorRenda,
        codigo_parceiro: 3033856,
        data_nascimento: birthDate,
        sexo: genre[0],
        percentual_corretagem: brokerage ? Number(brokerage) : 2,
        percentual_agenciamento: agency ? Number(agency) : 100,
        atividade_profissional: occupation || '111',
        coberturas: quotationData ? quotationData.coberturas : coversToSend,
        tipo_categoria_profissional: hasDit()
          ? tipoCategoriaProfissional ?? undefined
          : undefined,
        tipo_franquia: hasDit() ? tipoFranquia ?? undefined : undefined
      };

      if (commission) {
        const corretagemCalculada = Number(brokerage); /*- Number(commission)*/
        data = {
          ...data,
          percentual_corretagem: brokerage ? corretagemCalculada : 2,
          percentual_agenciamento: agency ? Number(agency) : 100,
          percentual_intermediacao: Number(commission)
        };
        baseData = {
          ...baseData,
          percentual_corretagem: brokerage ? corretagemCalculada : 2,
          percentual_agenciamento: agency ? Number(agency) : 100,
          percentual_intermediacao: Number(commission)
        };
      }

      const api = await apiSensedia();

      let baseDataRes = {} as AxiosResponse;

      let baseDataLooping = true;

      while (baseDataLooping) {
        try {
          baseDataRes = await api.post(
            `/v1/vendas/cotacoes?prodinfo=true`,
            baseData
          );

          baseDataLooping = false;
        } catch (e: any) {
          console.log(e.response.status, baseData);
          if (e.response.status == 500) {
            console.log('ocorreu internal server error', e.response.statusText);
            console.log('ocorreu internal server error', e.response.data);
          } else if (e.response.status == 504) {
            console.log('ocorreu gateway timeout', e.response.data);
          }
          if (e.response.status != 504) {
            //console.log('resposta erro', e.response);
            baseDataLooping = false;
            setOpenModal(false);
            toast({
              position: 'top',
              description: e.response.data.message,
              status: 'warning',
              duration: 60000,
              isClosable: true
            });
          }
        }
      }

      let res = {} as AxiosResponse;

      let looping = true;

      while (looping) {
        try {
          res = await api.post(`/v1/vendas/cotacoes?prodinfo=true`, data);
          looping = false;
        } catch (e: any) {
          console.log(e.response.status, data);
          if (e.response.status == 500) {
            console.log('ocorreu internal server error', e.response.statusText);
            console.log('ocorreu internal server error', e.response.data);
          } else if (e.response.status == 504) {
            console.log('ocorreu gateway timeout', e.response.data);
          }
          if (e.response.status != 504) {
            //console.log('resposta erro', e.response);
            looping = false;
            setOpenModal(false);
            toast({
              position: 'top',
              description: e.response.data.message,
              status: 'warning',
              duration: 60000,
              isClosable: true
            });
          }
        }
      }

      const productToSave = {
        price: res.data.valor_premio,
        price_desconto_campanha: res.data.valor_premio_desconto_campanha
          ? res.data.valor_premio_desconto_campanha
          : res.data.valor_premio,
        title: title,
        subtitle: subtitle,
        text: text,
        assistences: parsedAssist as { assist: string }[],
        covers: coversToSend as { cover: string; value: string }[],
        recommended: false,
        id: parts[0].replace(/[^0-9,]/gi, '')
      };
      const coversToSet: any[] = res.data.coberturas.map((cover: any) => {
        return {
          id: cover.id_cobertura,
          valor_premio_calculado: cover.valor_premio,
          valor_premio_calculado_desconto: cover.valor_premio_desconto_campanha
            ? cover.valor_premio_desconto_campanha
            : cover.valor_premio,
          valor_capital: cover.valor_capital_informado,
          descricao: cover.descricao_cobertura,
          html_description: cover.html_description,
          contratacao_obrigatoria: cover.contratacao_obrigatoria,
          type: 'cobertura',
          valor_minimo_capital: cover.valor_minimo_capital,
          valor_maximo_capital: cover.valor_maximo_capital,
          tabela_doencas: cover.tabela_doencas,
          icon_name: cover.icon_name,
          capitais_disponiveis: cover.capitais_disponiveis,
          possui_capitais_especificos: cover.possui_capitais_especificos
        };
      });
      const servicesToSet: any[] = res.data.servicos.map((servico: any) => {
        return {
          id: servico.id,
          valor_premio_calculado: servico.valor_premio,
          valor_premio_calculado_desconto:
            servico.valor_premio_desconto_campanha
              ? servico.valor_premio_desconto_campanha
              : servico.valor_premio,
          valor_capital: servico.valor_capital,
          descricao: servico.descricao_servico,
          html_description: servico.html_description,
          contratacao_obrigatoria: servico.contratacao_obrigatoria,
          type: 'servico',
          icon_name: servico.icon_name
        };
      });

      const coversStates = coversSwitchesState;
      const assistsStates = assistsSwitchesState;

      // se essa condição for verdadeira significa que é a primeira chamada
      if (!quotationData || chamadaConsiderandoProfissao) {
        const baseCoversToSet: any[] = baseDataRes.data.coberturas.map(
          (cover: any) => {
            const capitaisDisponiveis = mapaValoresMaximosCoberturas
              ? cover.capitais_disponiveis?.filter(
                  (el: any) =>
                    el <=
                    (mapaValoresMaximosCoberturas[cover.id_cobertura] ?? el)
                )
              : cover.capitais_disponiveis;
            const valorMaximoCapital =
              mapaValoresMaximosCoberturas![cover.id_cobertura] ??
              cover.valor_maximo_capital;

            return {
              id: cover.id_cobertura,
              valor_premio_calculado: cover.valor_premio,
              valor_premio_calculado_desconto:
                cover.valor_premio_desconto_campanha
                  ? cover.valor_premio_desconto_campanha
                  : cover.valor_premio,
              valor_capital: cover.valor_capital_informado,
              descricao: cover.descricao_cobertura,
              html_description: cover.html_description,
              contratacao_obrigatoria: cover.contratacao_obrigatoria,
              type: 'cobertura',
              valor_minimo_capital: cover.valor_minimo_capital,
              valor_maximo_capital: valorMaximoCapital,
              tabela_doencas: cover.tabela_doencas,
              icon_name: cover.icon_name,
              capitais_disponiveis: capitaisDisponiveis,
              possui_capitais_especificos: cover.possui_capitais_especificos
            };
          }
        );
        const baseServicesToSet: any[] = baseDataRes.data.servicos.map(
          (servico: any) => {
            return {
              id: servico.id,
              valor_premio_calculado: servico.valor_premio,
              valor_premio_calculado_desconto:
                servico.valor_premio_desconto_campanha
                  ? servico.valor_premio_desconto_campanha
                  : servico.valor_premio,
              valor_capital: servico.valor_capital,
              descricao: servico.descricao_servico,
              html_description: servico.html_description,
              contratacao_obrigatoria: servico.contratacao_obrigatoria,
              type: 'servico',
              icon_name: servico.icon_name
            };
          }
        );

        baseCoversToSet.forEach((item: any) => {
          coversStates[item.id] = { checked: false };
        });

        baseServicesToSet.forEach((item: any) => {
          assistsStates[item.id] = { checked: false };
        });

        coversToSet.forEach((item: any) => {
          coversStates[item.id] = { checked: true };
        });

        servicesToSet.forEach((item: any) => {
          assistsStates[item.id] = { checked: true };
        });

        setInitialCovers(baseCoversToSet);
        setInitialAssists(baseServicesToSet);
      } else {
        initialCovers.forEach((item: any) => {
          const contemItem = coversToSet
            .map((itemC) => {
              return itemC.id;
            })
            .includes(item.id);
          coversStates[item.id] = { checked: contemItem };
        });

        initialAssists.forEach((item: any) => {
          const contemItem = servicesToSet
            .map((itemS) => {
              return itemS.id;
            })
            .includes(item.id);
          assistsStates[item.id] = { checked: contemItem };
        });

        for (let i = 0; i < initialCovers.length; i++) {
          for (let j = 0; j < coversToSet.length; j++) {
            if (initialCovers[i].id === coversToSet[j].id) {
              initialCovers[i].valor_capital = coversToSet[j].valor_capital;
              initialCovers[i].valor_premio_calculado =
                coversToSet[j].valor_premio_calculado;

              if (
                initialCovers[i].tabela_doencas &&
                coversToSet[j].tabela_doencas
              ) {
                initialCovers[i].tabela_doencas.doencas = [
                  ...coversToSet[j].tabela_doencas.doencas
                ];
              }
            }
          }
        }

        for (let i = 0; i < initialAssists.length; i++) {
          for (let j = 0; j < servicesToSet.length; j++) {
            if (initialAssists[i].id === servicesToSet[j].id) {
              initialAssists[i].valor_capital = servicesToSet[j].valor_capital;
              initialAssists[i].valor_premio_calculado =
                servicesToSet[j].valor_premio_calculado;
            }
          }
        }

        setInitialCovers([...initialCovers]);
        setInitialAssists([...initialAssists]);
      }

      setCoberturasRetornadasDaCotacao(coversToSet);
      setAssistenciasRetornadasDaCotacao(servicesToSet);
      setCoversSwitchesState(coversStates);
      setAssistsSwitchesState(assistsStates);
      setProductSelected(productToSave);
      setOpenModal(false);
    } catch (erro) {
      console.log(erro);
    }
    window.scrollTo(0, 0);
  };

  const getCoverages = async () => {
    const api = await apiSensedia();
    let result = {} as AxiosResponse;

    try {
      result = await api.get(
        `/v1/produtos/${idProduto}/coberturas?id-plano=${getPlanId(
          idProduto
        )}&codigo-atividade-profissional=${occupation}&data-nascimento=${birthDate}`
      );
    } catch (error: any) {
      console.log(error);
    }

    setCoberturasProduto(result.data);

    return result.data as any[];
  };

  const getCoberturasBasicas = () => {
    const initialCoversIds = initialCovers.map((el) => el.id);
    return (coberturasProduto ?? [])
      .filter(
        (el) =>
          initialCoversIds.includes(el.id_cobertura) && el.tipo_garantia === 'B'
      )
      .map((el) => ({
        ...el,
        descricao: initialCovers.find((ell) => el.id_cobertura === ell.id)
          ?.descricao
      }));
  };

  const getCoberturasBasicasCotadas = () => {
    const coberturasBasicas = getCoberturasBasicas();

    const coberturasBasicasIds = coberturasBasicas.map((el) => el.id_cobertura);

    const coberturasBasicasCotadas = coberturasRetornadasDaCotacao.filter(
      (el) => coberturasBasicasIds.includes(el.id)
    );

    return coberturasBasicasCotadas;
  };

  const isCoberturaBasica = (idCobertura: number) => {
    const idsCoberturasBasicas = (coberturasProduto ?? [])
      .filter((el) => el.tipo_garantia === 'B')
      .map((el) => el.id_cobertura);

    return idsCoberturasBasicas.includes(idCobertura);
  };

  const joinWithOr = (array: string[]) => {
    if (array.length === 0) {
      return '';
    }

    if (array.length === 1) {
      return array[0];
    }

    let result = array.join(', ');
    result = result.replace(/,(?=[^,]*$)/, ' ou');
    return result;
  };

  const getDataProductSelectedConsideringOccupation = async () => {
    setOpenModal(true);
    const baseNewQuotationData = JSON.parse(
      JSON.stringify(parsedQuotationData)
    );

    const cvpCoversList = await getCoverages();

    const cvpCoversIdsList = cvpCoversList.map((el: any) => el.id_cobertura);

    const coberturasOriginais = baseNewQuotationData.coberturas as any[];

    const coberturasFiltradas = coberturasOriginais
      .filter((el) => {
        return cvpCoversIdsList.includes(el.id_cobertura);
      })
      .filter((el) => {
        return !(
          isCoberturaDIT(el.id_cobertura) &&
          el.valor_capital_informado > valorRenda
        );
      });

    const mapaValoresMaximosCoberturas = cvpCoversList.reduce(
      (accumulator, currentValue) => {
        const capitaisMaximos = [
          currentValue.capital_maximo_profissao,
          currentValue.valor_capital_maximo,
          currentValue.valor_capital_fixo
        ].filter((el) => el);

        const menorCapitalMaximoContratacao = Math.min(...capitaisMaximos);

        return {
          ...accumulator,
          [currentValue.id_cobertura]: menorCapitalMaximoContratacao
        };
      },
      {}
    );

    // trocar valores aqui
    const coberturasFiltradasCapitaisLimitados = coberturasFiltradas.map(
      (el) => {
        const menorCapitalMaximoContratacao =
          mapaValoresMaximosCoberturas[el.id_cobertura];

        if (el.valor_capital_informado > menorCapitalMaximoContratacao) {
          el.valor_capital_informado = menorCapitalMaximoContratacao;
        }

        return el;
      }
    );

    baseNewQuotationData.coberturas = coberturasFiltradasCapitaisLimitados;
    const newQuotationData = {
      ...baseNewQuotationData,
      coberturas: coberturasFiltradasCapitaisLimitados.filter((el) => {
        return !isCoberturaDIT(el.id_cobertura);
      })
    };

    try {
      setInitialFilteredDataState(
        JSON.parse(JSON.stringify(baseNewQuotationData))
      );
    } catch (e) {
      console.log(
        'erro ao setar coberturas iniciais filtradas',
        baseNewQuotationData
      );
    }

    if (coberturasFiltradas.length > 0) {
      setBtnContratarDisabled(false);

      await getDataProductSelected({
        quotationData: newQuotationData,
        initialQuotationData: baseNewQuotationData,
        chamadaConsiderandoProfissao: true,
        mapaValoresMaximosCoberturas: mapaValoresMaximosCoberturas
      });
    } else {
      setBtnContratarDisabled(true);
      toast({
        position: 'top',
        description: `Não há coberturas disponíveis para contratação com os dados informados.`,
        status: 'warning',
        duration: 30000,
        isClosable: true
      });
    }

    setOpenModal(false);
  };

  const getCampanhaDesconto = async () => {
    const api = await apiSensedia();
    let result = {} as AxiosResponse;

    try {
      result = await api.get(`/v1/produtos/${idProduto}/campanha-desconto`);
    } catch (error: any) {
      console.log(error);
    }

    const campanhaDesconto = result.data;

    setCampanhaDescontoBody(campanhaDesconto);
  };

  useEffect(() => {
    getDataProductSelectedConsideringOccupation();

    wlLocalStorage.removeItem('tipoCategoriaProfissional');
    wlLocalStorage.removeItem('tipoFranquia');
  }, []);

  const filterAdvantagesByProductType = () => {
    return advantages
      .filter((adv) => {
        const ids = getHiddenAssistsIds();

        const res = adv.ids?.find((advId) => {
          return ids.find((id) => advId == id);
        });

        return !res;
      })
      .filter((advantage) =>
        advantage.products.includes(getProductType(idProduto))
      );
  };

  const manageSelectedCoversAndServices = () => {
    const newQuotationData = JSON.parse(JSON.stringify(parsedQuotationData));

    if (editableQuotationData.id_produto) {
      for (let i = 0; i < newQuotationData.coberturas.length; i++) {
        for (let j = 0; j < editableQuotationData.coberturas.length; j++) {
          if (
            newQuotationData.coberturas[i].id_cobertura ===
            editableQuotationData.coberturas[j].id_cobertura
          ) {
            newQuotationData.coberturas[i] =
              editableQuotationData.coberturas[j];
          }
        }
      }

      for (let i = 0; i < newQuotationData.servicos.length; i++) {
        for (let j = 0; j < editableQuotationData.servicos.length; j++) {
          if (
            newQuotationData.servicos[i].id ===
            editableQuotationData.servicos[j].id
          ) {
            newQuotationData.servicos[i] = editableQuotationData.servicos[j];
          }
        }
      }
    }

    newQuotationData.coberturas = newQuotationData.coberturas.filter(
      (el: any) => {
        const switchInput = coberturasRef.current.querySelector(
          '.cover-item #switch-' + el.id_cobertura
        ) as HTMLInputElement;

        return switchInput?.checked;
      }
    );

    newQuotationData.servicos = newQuotationData.servicos.filter((el: any) => {
      const switchInput = assistenciasRef.current.querySelector(
        '.cover-item #switch-' + el.id
      ) as HTMLInputElement;

      return switchInput?.checked;
    });

    return newQuotationData;
  };

  const getOccupationNameByCodigo = (codigo: string) => {
    for (let i = 0; i < occupationList.length; i++) {
      if (occupationList[i].codigo === codigo) {
        return occupationList[i].descricao;
      }
    }

    return '111';
  };

  const advance = () => {
    wlLocalStorage.setItem(
      'newQuotationData',
      JSON.stringify(editableQuotationData)
    );
    wlLocalStorage.setItem(
      'coberturasRetornadasDaCotacao',
      JSON.stringify(coberturasRetornadasDaCotacao)
    );
    wlLocalStorage.setItem(
      'assistenciasRetornadasDaCotacao',
      JSON.stringify(assistenciasRetornadasDaCotacao)
    );

    window.location.href = `/pagamento/product=${idProduto}&cpf=${cpf}&user=${parts[2].replace(
      /[^0-9,]/gi,
      ''
    )}`;
  };

  const getModalEdicaoData = () => {
    const qtData = manageSelectedCoversAndServices();
    for (let i = 0; i < qtData.coberturas.length; i++) {
      if (coversModalEditionState[qtData.coberturas[i].id_cobertura]) {
        qtData.coberturas[i].valor_capital_informado =
          coversModalEditionState[
            qtData.coberturas[i].id_cobertura
          ].valor_capital;

        if (qtData.coberturas[i].tabela_doencas) {
          qtData.coberturas[i].tabela_doencas.doencas = [
            ...coversModalEditionState[qtData.coberturas[i].id_cobertura]
              .doencas_table.doencas
          ];
        }
      }
    }

    return qtData;
  };

  const handleModalEdicaoCoberturaSubmit = () => {
    const qtData = getModalEdicaoData();
    setEditableQuotationData(qtData);
    getDataProductSelected({
      quotationData: qtData,
      initialQuotationData: initialFilteredDataState
    });
  };

  useEffect(() => {
    const registroVenda: RegistroVendaProps = {
      nome_cliente: name,
      nome_social_cliente: socialName ?? null,
      telefone_cliente: phone,
      email_cliente: email,
      cpf_cliente: String(parts[1].replace(/[^0-9,]/gi, '')),
      data_nascimento_cliente: birthDate,
      codigo_profissao_cliente: occupation || '111',
      nome_profissao_cliente: getOccupationNameByCodigo(occupation),
      nome_produto: subtitle,
      id_produto_cvp: idProduto,
      id_website: idWebsite,
      id_acesso: idAcesso,
      ev: parseInt(codigo_estrutura_venda),
      codigo_corretor: parseInt(codigo_corretor)
    };

    apiSensedia().then(async (api) => {
      //salva no firestore
      const response = await api.post('/v1/metricas/vendas', registroVenda);

      //salva o id do retorno num sessionStorage
      sessionStorage.setItem('id_registro_venda', response.data.id);
    });
  }, []);

  useEffect(() => {
    getCampanhaDesconto();
  }, []);

  useEffect(() => {
    if (preventNextExecution.current) {
      preventNextExecution.current = false;
      return;
    }

    const firstRenderCoberturaBasica = coberturasBasicasFirstRenderRef.current;

    let peloMenosUmaCoberturaBasicaSelecionada = false;
    const idsCoberturas = Object.entries(coversSwitchesState).map((item) =>
      Number(item[0])
    );

    for (let i = 0; i < idsCoberturas.length; i++) {
      if (
        isCoberturaBasica(idsCoberturas[i]) &&
        coversSwitchesState[idsCoberturas[i]].checked
      ) {
        peloMenosUmaCoberturaBasicaSelecionada = true;
        break;
      }
    }

    if (peloMenosUmaCoberturaBasicaSelecionada) {
      const qtData = getModalEdicaoData();

      setEditableQuotationData(qtData);

      getDataProductSelected({
        quotationData: qtData,
        initialQuotationData: initialFilteredDataState
      });
    } else {
      const coberturasBasicas = getCoberturasBasicas();

      const coberturasBasicasDescricoes = coberturasBasicas.map(
        (el) => el.descricao
      );

      const coberturasBasicasDescricoesString = joinWithOr(
        coberturasBasicasDescricoes
      );

      if (!firstRenderCoberturaBasica) {
        toast({
          position: 'top',
          description:
            coberturasBasicasDescricoes.length > 1
              ? `Pelo menos uma das coberturas ${coberturasBasicasDescricoesString} deve ser selecionada.`
              : `A cobertura ${coberturasBasicasDescricoesString} deve ser selecionada.`,
          status: 'warning',
          duration: 5000,
          isClosable: true
        });

        const coversSwitchesStateEntries: any[] =
          Object.entries(coversSwitchesState);
        const lastCoversSwitchesStateEntries: any[] = Object.entries(
          lastCoversSwitchesStateValue.current
        );

        const lastUncheckedCover = coversSwitchesStateEntries.find((el) =>
          lastCoversSwitchesStateEntries.find(
            (ell) => el[0] === ell[0] && el[1]?.checked !== ell[1]?.checked
          )
        );

        if (lastUncheckedCover) {
          coversSwitchesState[lastUncheckedCover[0]].checked = true;
          setCoversSwitchesState({ ...coversSwitchesState });

          preventNextExecution.current = true;
        }
      } else {
        coberturasBasicasFirstRenderRef.current = false;
      }
    }

    lastCoversSwitchesStateValue.current = JSON.parse(
      JSON.stringify(coversSwitchesState)
    );
    lastAssistsSwitchesStateValue.current = JSON.parse(
      JSON.stringify(assistsSwitchesState)
    );
  }, [coversSwitchesState, assistsSwitchesState]);

  const memoAdvantages = useMemo(() => filterAdvantagesByProductType(), []);

  return (
    <Box w="100%">
      <LayoutContent currentStep={0} zIndex={10} title={''}>
        <Box
          ref={cardCheckoutRef}
          w="100%"
          maxW={['328px', '1192px']}
          display="flex"
          justifyContent="center"
        >
          <CardCheckout
            iconProduct={(() => {
              const prodType = getProductType(idProduto);
              if (prodType == 'vida_individual' || prodType == 'vida_flex') {
                return VidaIndividualIcon;
              }

              if (prodType == 'acidentes_pessoais') {
                return AcidentesPessoaisIcon;
              }
            })()}
            title={subtitle}
            protectionValue={getCoberturasBasicasCotadas()[0]?.valor_capital}
            installmentValue={Number(
              getInstallmentOver10(productSelected?.price).installment.toFixed(
                2
              )
            )}
            frequency={
              getMonthNameByChar(
                getInstallmentOver10(productSelected?.price)
                  .frequency as PaymentFrequencyType
              )?.toLowerCase() as string
            }
            onClick={() => {
              advance();
            }}
            isDisable={isBtnContratarDisabled}
          />
        </Box>
        <Box w="100%" maxW={['328px', '1192px']}>
          <Box bg="#001E64" p="10px 0" m="20px 0 0 0" textAlign="center">
            <Text
              color="#FFFFFF"
              fontSize="1.25rem"
              lineHeight="2rem"
              fontWeight="700"
            >
              Coberturas
            </Text>
          </Box>
          <Box margin="0 !important" ref={coberturasRef}>
            <DropdownCheckout
              idProduto={Number(productSelected.id)}
              coverages={initialCovers}
              isProductVariable={isProductVariable()}
              states={coversSwitchesState}
              setStates={(states: any) => {
                setCoversSwitchesState(states);
              }}
              modalEditionState={coversModalEditionState}
              setModalEditionState={(states: any) => {
                setCoversModalEditionState(states);
              }}
              handleModalSubmit={handleModalEdicaoCoberturaSubmit}
              idProdutoCustomizado={idProdutoCustomizado}
              idWebsite={idWebsite}
              frequencyChar={
                getInstallmentOver10(productSelected?.price)
                  .frequency as PaymentFrequencyType
              }
            />
          </Box>
          <Box bg="#001E64" p="10px 0" m="20px 0 0 0" textAlign="center">
            <Text
              color="#FFFFFF"
              fontSize="1.25rem"
              lineHeight="2rem"
              fontWeight="700"
            >
              Assistências
            </Text>
          </Box>
          <Box margin="0 !important" ref={assistenciasRef}>
            <DropdownCheckout
              idProduto={Number(productSelected.id)}
              coverages={initialAssists}
              isProductVariable={isProductVariable()}
              states={assistsSwitchesState}
              setStates={(states: any[]) => {
                setAssistsSwitchesState(states);
              }}
              modalEditionState={coversModalEditionState}
              setModalEditionState={(states: any) => {
                setCoversModalEditionState(states);
              }}
              handleModalSubmit={() => {
                //
              }}
              idProdutoCustomizado={idProdutoCustomizado}
              idWebsite={idWebsite}
              frequencyChar={
                getInstallmentOver10(productSelected?.price)
                  .frequency as PaymentFrequencyType
              }
            />
          </Box>
        </Box>
        <Text
          m={['10px 0 20px', '20px 0 50px 0 !important']}
          color="#323232"
          fontSize={['0.75rem', '1rem']}
          lineHeight={['1rem', '1.5rem']}
          fontWeight="400"
          textAlign={['center', 'start']}
        >
          Gostaria de mais informações sobre as coberturas e assistências?&nbsp;
          <Link
            color="#146CFA"
            href={`/documentos/guia_produto_${idProduto}.pdf`}
            target="_blank"
          >
            Veja o Guia do Produto
          </Link>
        </Text>
      </LayoutContent>
      <Box
        w="100%"
        display="flex"
        flexDirection="column"
        gridGap={['0', '30px']}
        bg="#F4F4F4"
      >
        <Box w="100%" p={['0', '0 0 136px']}>
          <SectionResumo advantages={memoAdvantages} idProduto={idProduto} />
        </Box>
        <HStack
          flexDir="column"
          w="100%"
          position={['relative', 'fixed']}
          bottom="0"
          zIndex="1"
          bg="#F4F4F4"
        >
          {/* <SliderResumo
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            handleSliderValue={handleSliderValue}
          /> */}
          <BuyConfirmation
            price={Number(
              getInstallmentOver10(productSelected?.price).installment
            )}
            frequency={
              getInstallmentOver10(
                campanhaDescontoBody.campanha
                  ? productSelected?.price *
                      (1 -
                        campanhaDescontoBody.campanha.percentual_desconto / 100)
                  : productSelected?.price
              ).frequency as PaymentFrequencyType
            }
            percentualDesconto={
              campanhaDescontoBody.campanha?.percentual_desconto
            }
            actionNext={() => {
              advance();
            }}
            actionPrev={() => {
              history.back();
            }}
            isDisable={isBtnContratarDisabled}
          />
        </HStack>
      </Box>
      <ModalLoad isOpenModal={openModal} content={'Buscando produto...'} />
    </Box>
  );
};

import { Box, Button, Input, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

type QuizCondicoesSaudeProps = {
  reference: React.MutableRefObject<HTMLDivElement>;
  questions: {
    alternativa_obs_obrigatoria: string;
    id_pergunta: number;
    codigo: number;
    numero: number;
    descricao: string;
    tipo_pergunta: string;
  }[];
  questionsInternalState: any;
  setQuestionsInternalState: (val: any) => void;
};

export const QuizCondicoesSaude = ({
  reference,
  questions,
  questionsInternalState,
  setQuestionsInternalState
}: QuizCondicoesSaudeProps) => {
  const refButtons = useRef() as React.MutableRefObject<HTMLDivElement>;

  const getQuestionIdByIndex = (index: number) => {
    return Object.entries(questionsInternalState)
      .map((item: any) => {
        return { ...item[1], id_question: item[0] };
      })
      .filter((item) => {
        return item.questionNumber === index;
      })[0]?.id_question;
  };

  console.log('perguntas aqui', questions, questionsInternalState);

  return (
    <>
      {questions.map((question, index) => {
        return (
          <Box
            ref={reference}
            id={'question-' + question.id_pergunta}
            key={index}
            p={{ base: '24px 22px', lg: '32px 103px' }}
            maxW="784px"
            w="100%"
            bg="#FFFFFF"
            boxShadow=" 0px 4px 10px rgba(50, 50, 50, 0.16)"
          >
            <Text
              w="100%"
              fontSize="0.875rem"
              fontWeight={400}
              lineHeight="1.5rem"
              color="#323232"
            >
              {(() => {
                const descricaoPergunta = question.descricao;

                const descricaoArray = descricaoPergunta.split('.');
                descricaoArray[0] = (index + 1).toString();

                return descricaoArray.join('.');
              })()}
            </Text>
            {question.tipo_pergunta != 'D' ? (
              <Box
                ref={refButtons}
                mt="24px"
                w="100%"
                display="flex"
                gridGap="24px"
              >
                <Button
                  bg={
                    questionsInternalState[question.id_pergunta]?.option == 'S'
                      ? '#FF5000'
                      : '#FFFFFF'
                  }
                  color={
                    !(
                      questionsInternalState[question.id_pergunta]?.option ==
                      'S'
                    )
                      ? '#FF5000'
                      : '#FFFFFF'
                  }
                  p="12px 32px"
                  disabled={(() => {
                    const previousQuestionId = getQuestionIdByIndex(index - 1);
                    if (index <= 0) {
                      return false;
                    }

                    if (
                      questionsInternalState[previousQuestionId]
                        ?.questionType == 'D' &&
                      questionsInternalState[previousQuestionId]
                        ?.questionDescription
                    ) {
                      return false;
                    }

                    if (
                      questionsInternalState[previousQuestionId]?.option ===
                        'S' &&
                      !questionsInternalState[previousQuestionId]
                        ?.questionDescription
                    ) {
                      return true;
                    }
                    if (
                      questionsInternalState[previousQuestionId]?.option !=
                        'S' &&
                      questionsInternalState[previousQuestionId]?.option != 'N'
                    ) {
                      return true;
                    }
                  })()}
                  border="1px solid #FF5000"
                  borderRadius="4px !important"
                  onClick={() => {
                    questionsInternalState[question.id_pergunta] = {
                      ...questionsInternalState[question.id_pergunta],
                      option: 'S',
                      questionNumber: index,
                      questionType: question.tipo_pergunta
                    };

                    setQuestionsInternalState({ ...questionsInternalState });
                    // handleActiveButton(index);
                  }}
                  _hover={{}}
                  _focus={{
                    '&:focus': {
                      boxShadow: 'none !important'
                    }
                  }}
                >
                  SIM
                </Button>
                <Button
                  bg={
                    questionsInternalState[question.id_pergunta]?.option == 'N'
                      ? '#FF5000'
                      : '#FFFFFF'
                  }
                  p="12px 32px"
                  disabled={(() => {
                    const previousQuestionId = getQuestionIdByIndex(index - 1);
                    if (index <= 0) {
                      return false;
                    }

                    if (
                      questionsInternalState[previousQuestionId]
                        ?.questionType == 'D' &&
                      questionsInternalState[previousQuestionId]
                        ?.questionDescription
                    ) {
                      return false;
                    }

                    if (
                      questionsInternalState[previousQuestionId]?.option ===
                        'S' &&
                      !questionsInternalState[previousQuestionId]
                        ?.questionDescription
                    ) {
                      return true;
                    }
                    if (
                      questionsInternalState[previousQuestionId]?.option !=
                        'S' &&
                      questionsInternalState[previousQuestionId]?.option != 'N'
                    ) {
                      return true;
                    }
                  })()}
                  border="1px solid #FF5000"
                  color={
                    !(
                      questionsInternalState[question.id_pergunta]?.option ==
                      'N'
                    )
                      ? '#FF5000'
                      : '#FFFFFF'
                  }
                  borderRadius="4px !important"
                  onClick={() => {
                    questionsInternalState[question.id_pergunta] = {
                      ...questionsInternalState[question.id_pergunta],
                      option: 'N',
                      questionNumber: index,
                      questionType: question.tipo_pergunta
                    };

                    setQuestionsInternalState({ ...questionsInternalState });
                  }}
                  _hover={{}}
                  // _active={{}}
                  _focus={{
                    '&:focus': {
                      boxShadow: 'none !important'
                    }
                  }}
                >
                  NÃO
                </Button>
              </Box>
            ) : undefined}
            {(questionsInternalState[question.id_pergunta] &&
              questionsInternalState[question.id_pergunta].option === 'S') ||
            question.tipo_pergunta === 'D' ? (
              <Box
                mt={question.tipo_pergunta != 'D' ? '32px' : '16px'}
                display="flex"
                flexDirection="column"
                gridGap="6px"
              >
                <Text
                  fontSize="0.875rem"
                  fontWeight={400}
                  lineHeight="1.5rem"
                  color="#828282"
                >
                  {question.tipo_pergunta != 'D'
                    ? 'Descreva a condição:*'
                    : 'Preencha o campo:*'}
                </Text>
                <Input
                  border="1px solid #FF5000 !important"
                  borderRadius="0 !important"
                  type="text"
                  _hover={{}}
                  _active={{}}
                  _focus={{
                    '&:focus': {
                      boxShadow: 'none !important'
                    }
                  }}
                  // value={answerOfFirstQuestion}
                  onChange={(event) => {
                    // setAnswerOfFirstQuestion(event.target.value);

                    questionsInternalState[question.id_pergunta] = {
                      ...questionsInternalState[question.id_pergunta],
                      questionNumber: index,
                      questionDescription: event.target.value,
                      // answerOfFirstQuestion
                      questionType: question.tipo_pergunta
                    };

                    setQuestionsInternalState({ ...questionsInternalState });
                  }}
                  value={
                    questionsInternalState[question.id_pergunta]
                      .questionDescription
                  }
                />
              </Box>
            ) : undefined}
          </Box>
        );
      })}
    </>
  );
};

import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';

import { getNumberFormat } from '../../utils/getNumberFormat';
import {
  getInstallmentOver10,
  getInstallmentsNumberByChar,
  PaymentFrequencyType
} from '../../utils/money.util';

type CardPagamentoCheckOutBottomProps = {
  price: number;
  title: string;
  installments: string;
  typeOfPayment: string;
};

export const CardPagamentoCheckOutBottom = ({
  price,
  title,
  installments,
  typeOfPayment
}: CardPagamentoCheckOutBottomProps) => {
  // const totalPrice = price < 1.0 ? price * 10000 : price;
  const text =
    typeOfPayment === 'pix'
      ? '* O parcelamento em 12x é limitado, a parcela mensal é no mínimo R$ 10,00'
      : typeOfPayment === 'debito'
      ? '*O parcelamento em 12x é limitado, a parcela mensal é no mínimo R$ 10,00'
      : '*O parcelamento em 12x é limitado, a parcela mensal é no mínimo R$ 10,00. Desconto de 5% para pagamento no Cartão de Crédito';

  const installment =
    installments === 'A'
      ? 1
      : installments === 'T'
      ? 4
      : installments === 'S'
      ? 2
      : installments === 'M'
      ? 12
      : getInstallmentsNumberByChar(
          getInstallmentOver10(price).frequency as PaymentFrequencyType
        );

  return (
    <>
      <VStack
        m="30px 0 0 0 !important"
        p={['16px', '20px 25px']}
        maxW="850px"
        w="100%"
        bg="#FFFFFF"
        boxShadow="0px 4px 10px rgba(50, 50, 50, 0.16);"
        borderRadius="0 !important"
      >
        <Stack w="100%">
          <Text
            w="100%"
            m="0 !important"
            color="#323232"
            fontSize={['0.875rem', '1rem']}
            lineHeight={['1.25ren', '1.5rem']}
            fontWeight="700"
          >
            Sua compra
          </Text>
          <Text
            w="100%"
            m={['10px 0 0 0 !important', '5px 0 0 0 !important']}
            color="#FF5000"
            fontSize={['1rem', '1.25rem']}
            lineHeight={['1.25rem', '2rem']}
            fontWeight="700"
          >
            {title}
          </Text>
          {/* <Text
            w="100%"
            m="0 !important"
            color={
              installments === ''
                ? '#323232'
                : defaultColor
                ? defaultColor
                : ' #FF5000'
            }
            fontSize={installments === '' ? '0.75rem' : '1rem'}
            lineHeight={installments === '' ? '0.75rem' : '1.5rem'}
            fontWeight="400"
          >
            {installments === 'A' || installments === ''
              ? 'Plano Anual'
              : installments === 'S'
              ? 'Plano Semestral'
              : installments === 'T'
              ? 'Plano Trimestral'
              : 'Plano Mensal'}
          </Text> */}
        </Stack>
        <HStack
          w="100%"
          m="20px 0 0 0 !important"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            m="0 !important"
            color="#323232"
            fontSize="1rem"
            lineHeight="1.5rem"
            fontWeight="700"
          >
            Valor:
          </Text>
          <Text
            m="0 !important"
            color="#000000"
            fontSize={['1rem', '1.25rem']}
            lineHeight={['1.25px', '2rem']}
            fontWeight="700"
          >
            {installment} x de&nbsp;
            <span style={{ color: '#FF5000' }}>
              {getNumberFormat((price / installment).toFixed(2))}
            </span>
            &nbsp;sem juros*
          </Text>
        </HStack>
        <Box w="100%">
          <Text
            maxW="415px"
            w="100%"
            m="20px 0 0 0 !important"
            color="#323232"
            fontSize="0.75rem"
            lineHeight="1.25rem"
            fontWeight="400"
          >
            {text}
          </Text>
        </Box>
      </VStack>
    </>
  );
};

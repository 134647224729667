export type PaymentFrequencyType = 'M' | 'T' | 'S' | 'A';

export const getInstallmentOver10 = (value: number) => {
  //Mensal
  let installment = value / 12;

  if (installment >= 10) {
    return { installment, frequency: 'M' }
  }

  //Trimestral
  installment = value / 4;

  if (installment >= 10) {
    return { installment, frequency: 'T' }
  }

  //Semestral
  installment = value / 2;

  if (installment >= 10) {
    return { installment, frequency: 'S' }
  }

  //Anual
  installment = value;

  return { installment, frequency: 'A' }
}

export const getMonthNameByChar = (char: PaymentFrequencyType) => {
  if (char === 'M') {
    return 'Mês';
  }

  if (char === 'T') {
    return 'Trimestre';
  }

  if (char === 'S') {
    return 'Semestre';
  }

  if (char === 'A') {
    return 'Ano';
  }
}

export const getInstallmentsNumberByChar = (char: PaymentFrequencyType) => {
  if (char === 'M') {
    return 12;
  }

  if (char === 'T') {
    return 4;
  }

  if (char === 'S') {
    return 2;
  }

  return 1
}
import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Text
} from '@chakra-ui/react';

import { BeneficiariosIcon } from '../../assets/icons/diversos/BeneficiariosIcons';
import { ButtonConfirm } from '../buttons/ButtonConfirm';

type ModalConfirmProps = {
  // isOpenModal: boolean;
  hrefNext: string;
  handleClose: () => void;
};

export const ModalConfirm = ({
  // isOpenModal,
  hrefNext,
  handleClose
}: ModalConfirmProps) => {
  return (
    <>
      <Modal isOpen={true} onClose={handleClose}>
      <ModalOverlay bg="#00000056" />
        <ModalContent
          m="0 !important"
          p={{ base: '16px 28px', md: '20px 35px 30px' }}
          position="absolute"
          top="25%"
          w="90%"
          maxW="395px !important"
          borderRadius="0 !important"
          background="#FFFFFF"
          boxShadow="0px 0px 5px rgba(50, 50, 50, 0.25)"
        >
          <Button
            bg="transparent"
            w="20px"
            h="20px"
            color="#323232"
            position="absolute"
            top="20px"
            left="90%"
            _hover={{}}
            _active={{}}
            _focus={{
              '&:focus': {
                boxShadow: 'none !important'
              }
            }}
            onClick={handleClose}
          >
            X
          </Button>
          <Box
            p={{ base: '20px 0 0', md: '20px 20px 0' }}
            display="flex"
            flexDirection="column"
            gridGap="16px"
          >
            <Box w="100%" textAlign="center">
              <BeneficiariosIcon w="55px" h="55px" />
            </Box>
            <Text
              m="0 !important"
              fontSize="1rem"
              lineHeight="1.5rem"
              fontWeight="700"
              color="#323232"
              textAlign="center"
            >
              Os beneficiários serão indicados como Herdeiros Legais e poderão
              ser alterados a qualquer momento no Espaço do Cliente.
            </Text>
            <ButtonConfirm
              handleConfirm={() => {
                window.location.href = hrefNext;
              }}
            />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

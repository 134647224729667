import { Box, Heading } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

import { ButtonNavegate } from '../components/buttons/ButtonNavegate';
import { FormDadosCadastrais } from '../components/forms/FormDadosCadastrais';
import { FormEndereco } from '../components/forms/FormEndereco';
import { FormResponsavelLegal } from '../components/forms/FormResponsavelLegal';
import { ConsentTerm } from '../components/fragments/ConsentTerm';
import { LayoutContent } from '../components/layouts/LayoutContent';
import { pushToDataLayer } from '../utils/data-layer.util';
import { getParams } from '../utils/getParams';
import { isJson } from '../utils/json.util';
import { wlLocalStorage } from '../utils/localStorage.util';
import {
  getProductType,
  hasDit,
  isCoberturaDIT
} from '../utils/products.utils';
import { isValidEmail } from '../utils/string-utils';
import {
  WL_MINIMUM_CUSTOMER_AGE,
  WL_STARTER_LEGAL_AGE
} from '../utils/wl-consts.util';

export const DadosCadastraisFormularios = () => {
  const [clientEnteredAge, setClientEnteredAge] = useState<number>(
    Number(wlLocalStorage.getItem('clientEnteredAge')) || 0
  );
  const [legalGuardianEnteredAge, setLegalGuardianEnteredAge] =
    useState<number>(
      Number(wlLocalStorage.getItem('legalGuardianEnteredAge')) || 0
    );

  const [disableButtonNav, setDisableButtonNav] = useState(true);
  const [name, setName] = useState<string>(
    wlLocalStorage.getItem('name') || ''
  );
  const [socialName, setSocialName] = useState<string>(
    wlLocalStorage.getItem('socialName') || ''
  );

  const [hasSocialName, setHasSocialName] = useState(
    Boolean(wlLocalStorage.getItem('socialName') || '')
  );

  const [cpf, setCpf] = useState<string>(wlLocalStorage.getItem('cpf') || '');
  const [date, setDate] = useState<string>(
    wlLocalStorage.getItem('date') || ''
  );
  const [genre, setGenre] = useState<string>(
    wlLocalStorage.getItem('genre') || ''
  );
  const [occupation, setOccupation] = useState<string>(
    wlLocalStorage.getItem('occupation') || ''
  );
  const [occupationCode, setOccupationCode] = useState<string>(
    wlLocalStorage.getItem('occupationSelected') || ''
  );

  const [income, setIncome] = useState<string>(
    wlLocalStorage.getItem('valor_renda')
      ? (() => {
          let renda = wlLocalStorage.getItem('valor_renda');
          renda = renda.replace('.', '').replace(',', '').replace(/\D/g, '');

          const options = { minimumFractionDigits: 2 };
          const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(renda) / 100
          );

          return `R$ ${result}`;
        })()
      : ''
  );

  const [phone, setPhone] = useState<string>(
    wlLocalStorage.getItem('phone') || ''
  );
  const [cep, setCep] = useState<string>(wlLocalStorage.getItem('cep') || '');
  const [address, setAddress] = useState<string>(
    wlLocalStorage.getItem('address') || ''
  );
  const [number, setNumber] = useState<string>(
    wlLocalStorage.getItem('number') || ''
  );
  const [complement, setComplement] = useState<string>(
    wlLocalStorage.getItem('complement') || ''
  );
  const [district, setDistrict] = useState<string>(
    wlLocalStorage.getItem('district') || ''
  );
  const [city, setCity] = useState<string>(
    wlLocalStorage.getItem('city') || ''
  );
  const [uf, setUf] = useState<string>(wlLocalStorage.getItem('uf') || '');
  const [weight, setWeight] = useState<string>(
    wlLocalStorage.getItem('weight') || ''
  );
  const [height, setHeight] = useState<string>(
    wlLocalStorage.getItem('height') || ''
  );
  const [email, setEmail] = useState<string>(
    wlLocalStorage.getItem('email') || ''
  );
  const [confirmedEmail, setConfirmedEmail] = useState<string>('');

  const [nameLegalGuardian, setNameLegalGuardian] = useState<string>(
    wlLocalStorage.getItem('nameLegalGuardian') || ''
  );
  const [cpfLegalGuardian, setCpfLegalGuardian] = useState<string>(
    wlLocalStorage.getItem('cpfLegalGuardian') || ''
  );
  const [dateLegalGuardian, setDateLegalGuardian] = useState<string>(
    wlLocalStorage.getItem('dateLegalGuardian') || ''
  );
  const [genreLegalGuardian, setGenreLegalGuardian] = useState<string>(
    wlLocalStorage.getItem('genreLegalGuardian') || ''
  );
  const [occupationLegalGuardian, setOccupationLegalGuardian] =
    useState<string>(wlLocalStorage.getItem('occupationLegalGuardian') || '');
  const [occupationCodeLegalGuardian, setOccupationCodeLegalGuardian] =
    useState<string>(
      wlLocalStorage.getItem('occupationSelectedLegalGuardian') || ''
    );
  const [incomeLegalGuardian, setIncomeLegalGuardian] = useState<string>(
    wlLocalStorage.getItem('valor_renda_legal_guardian')
      ? (() => {
          let renda = wlLocalStorage.getItem('valor_renda_legal_guardian');
          renda = renda.replace('.', '').replace(',', '').replace(/\D/g, '');

          const options = { minimumFractionDigits: 2 };
          const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(renda) / 100
          );

          return `R$ ${result}`;
        })()
      : ''
  );
  const [kinshipDegree, setkinshipDegree] = useState<string>(
    wlLocalStorage.getItem('kinshipDegree') || ''
  );
  const [phoneLegalGuardian, setPhoneLegalGuardian] = useState<string>(
    wlLocalStorage.getItem('phoneLegalGuardian') || ''
  );
  const [emailLegalGuardian, setEmailLegalGuardian] = useState<string>(
    wlLocalStorage.getItem('emailLegalGuardian') || ''
  );
  const [confirmedEmailLegalGuardian, setConfirmedEmailLegalGuardian] =
    useState<string>('');

  const [idProduct, setIdProduct] = useState('00');

  const [widthForRef, setWidthForRef] = useState(0);

  const divFormRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const query = location.pathname;
  const parts = query.split('&');
  const user = parts[2].replace(/[^0-9,]/gi, '');

  const handleDatesConfirmed = () => {
    const formattedHeight = height.replaceAll(',', '');
    wlLocalStorage.setItem('name', name);
    wlLocalStorage.setItem('socialName', socialName);
    wlLocalStorage.setItem('cpf', String(cpf));
    wlLocalStorage.setItem('date', date);
    wlLocalStorage.setItem('genre', genre);
    wlLocalStorage.setItem('occupation', occupation);
    wlLocalStorage.setItem(
      'valor_renda',
      income
        .replaceAll('R$', '')
        .replaceAll(' ', '')
        .replaceAll('.', '')
        .replaceAll(',', '.')
    );
    wlLocalStorage.setItem('phone', phone);
    wlLocalStorage.setItem('cep', cep);
    wlLocalStorage.setItem('address', address);
    wlLocalStorage.setItem('number', number);
    wlLocalStorage.setItem('complement', complement);
    wlLocalStorage.setItem('district', district);
    wlLocalStorage.setItem('city', city);
    wlLocalStorage.setItem('uf', uf);
    wlLocalStorage.setItem('email', email);
    wlLocalStorage.setItem('weight', weight);
    wlLocalStorage.setItem('height', formattedHeight);
    wlLocalStorage.setItem('nameLegalGuardian', nameLegalGuardian);
    wlLocalStorage.setItem('cpfLegalGuardian', cpfLegalGuardian);
    wlLocalStorage.setItem('dateLegalGuardian', dateLegalGuardian);
    wlLocalStorage.setItem('genreLegalGuardian', genreLegalGuardian);
    wlLocalStorage.setItem('occupationLegalGuardian', occupationLegalGuardian);
    wlLocalStorage.setItem(
      'occupationSelectedLegalGuardian',
      occupationCodeLegalGuardian
    );
    wlLocalStorage.setItem(
      'valor_renda_legal_guardian',
      incomeLegalGuardian
        .replaceAll('R$', '')
        .replaceAll(' ', '')
        .replaceAll('.', '')
        .replaceAll(',', '.')
    );
    wlLocalStorage.setItem('kinshipDegree', kinshipDegree);
    wlLocalStorage.setItem('phoneLegalGuardian', phoneLegalGuardian);
    wlLocalStorage.setItem('emailLegalGuardian', emailLegalGuardian);

    if (
      name?.length > 0 &&
      (hasSocialName ? socialName?.length > 0 : true) &&
      date?.length > 0 &&
      clientEnteredAge >= WL_MINIMUM_CUSTOMER_AGE &&
      genre?.length > 0 &&
      (weight?.length > 0 ||
        getProductType(Number(idProduct)) === 'acidentes_pessoais') &&
      (height?.length > 0 ||
        getProductType(Number(idProduct)) === 'acidentes_pessoais') &&
      (phone.replaceAll('_', '').length === 14 || String(phone).length == 11) &&
      cep.replaceAll('_', '').replaceAll('-', '').length === 8 &&
      address?.length > 0 &&
      number?.length > 0 &&
      district?.length > 0 &&
      city?.length > 0 &&
      uf.replaceAll('_', '').length === 2 &&
      email?.length > 0 &&
      isValidEmail(email) &&
      occupationCode?.length > 0 &&
      income?.length > 0 &&
      confirmedEmail === email &&
      (clientEnteredAge < WL_STARTER_LEGAL_AGE
        ? nameLegalGuardian?.length > 0 &&
          dateLegalGuardian?.length > 0 &&
          genreLegalGuardian?.length > 0 &&
          occupationCodeLegalGuardian?.length > 0 &&
          incomeLegalGuardian?.length > 0 &&
          kinshipDegree?.length > 0 &&
          phoneLegalGuardian?.length > 0 &&
          emailLegalGuardian?.length > 0 &&
          isValidEmail(emailLegalGuardian) &&
          emailLegalGuardian === confirmedEmailLegalGuardian &&
          cpfLegalGuardian
            ?.replaceAll('.', '')
            .replaceAll('-', '')
            .replaceAll('_', '').length === 11 &&
          cpfLegalGuardian
            ?.replaceAll('.', '')
            .replaceAll('-', '')
            .replaceAll('_', '') !==
            cpf?.replaceAll('.', '').replaceAll('-', '').replaceAll('_', '') &&
          legalGuardianEnteredAge >= 18
        : true)
    ) {
      setDisableButtonNav(false);
    } else {
      setDisableButtonNav(true);
    }
  };

  const getAddress = async () => {
    const cepOnly = cep.replaceAll('-', '');
    if (cepOnly[7] != '_') {
      try {
        const res = await axios.get(
          `https://viacep.com.br/ws/${cepOnly}/json/`
        );
        if (res.data.erro != true) {
          setAddress(res.data.logradouro);
          setDistrict(res.data.bairro);
          setCity(res.data.localidade);
          setUf(res.data.uf);
        }
      } catch (erro) {
        // console.log(erro);
      }
    }
  };

  useEffect(() => {
    if (!hasSocialName) {
      setSocialName('');
    }
  }, [hasSocialName]);

  useEffect(() => {
    handleDatesConfirmed();
  }, [
    name,
    socialName,
    hasSocialName,
    date,
    genre,
    occupation,
    income,
    phone,
    cep,
    address,
    number,
    district,
    city,
    uf,
    email,
    confirmedEmail,
    nameLegalGuardian,
    dateLegalGuardian,
    genreLegalGuardian,
    occupationLegalGuardian,
    incomeLegalGuardian,
    phoneLegalGuardian,
    emailLegalGuardian,
    confirmedEmailLegalGuardian,
    kinshipDegree,
    height,
    weight
  ]);

  useEffect(() => {
    setCpf(parts[1].replaceAll('cpf=', ''));
    setIdProduct(parts[0].replace(/[^0-9,]/gi, ''));
    setWidthForRef(divFormRef.current?.clientWidth);
  }, []);

  return (
    <LayoutContent currentStep={0} zIndex={10} title={'Dados Cadastrais'}>
      <Box
        ref={divFormRef}
        w="100%"
        p={{ base: '0', md: '30px' }}
        display="flex"
        justifyContent="center"
      >
        <FormDadosCadastrais
          email={email}
          setEmail={setEmail}
          confirmedEmail={confirmedEmail}
          setConfirmedEmail={setConfirmedEmail}
          name={name}
          setName={setName}
          socialName={socialName}
          setSocialName={setSocialName}
          hasSocialName={hasSocialName}
          setHasSocialName={setHasSocialName}
          date={date}
          setDate={setDate}
          genre={genre}
          setGenre={setGenre}
          occupation={occupation}
          setOccupation={setOccupation}
          phone={phone}
          setPhone={setPhone}
          peso={weight}
          setPeso={setWeight}
          altura={height}
          setOccupationCode={setOccupationCode}
          setAltura={setHeight}
          renda={income}
          setRenda={setIncome}
          clientsAge={clientEnteredAge}
          setClientsAge={setClientEnteredAge}
          idProduct={idProduct}
        />
      </Box>
      {clientEnteredAge >= WL_MINIMUM_CUSTOMER_AGE &&
      clientEnteredAge < WL_STARTER_LEGAL_AGE ? (
        <>
          <Heading
            w="100%"
            m={{
              base: '18px 0 36px 0 !important',
              lg: '36px 0 24px 0 !important'
            }}
            color="#000000"
            fontSize={['1.5rem', '1.5rem', '2rem']}
            fontWeight="700"
            lineHeight={['2rem', '2rem', '3rem']}
            display="flex"
            justifyContent="center"
          >
            Dados do Responsável
          </Heading>
          <Box
            w="100%"
            p={{ base: '0', md: '30px' }}
            display="flex"
            justifyContent="center"
          >
            <FormResponsavelLegal
              emailLegalGuardian={emailLegalGuardian}
              setEmailLegalGuardian={setEmailLegalGuardian}
              confirmedEmailLegalGuardian={confirmedEmailLegalGuardian}
              setConfirmedEmailLegalGuardian={setConfirmedEmailLegalGuardian}
              nameLegalGuardian={nameLegalGuardian}
              setNameLegalGuardian={setNameLegalGuardian}
              dateLegalGuardian={dateLegalGuardian}
              setDateLegalGuardian={setDateLegalGuardian}
              genreLegalGuardian={genreLegalGuardian}
              setGenreLegalGuardian={setGenreLegalGuardian}
              occupationLegalGuardian={occupationLegalGuardian}
              setOccupationLegalGuardian={setOccupationLegalGuardian}
              phoneLegalGuardian={phoneLegalGuardian}
              setPhoneLegalGuardian={setPhoneLegalGuardian}
              cpfLegalGuardian={cpfLegalGuardian}
              setCpfLegalGuardian={setCpfLegalGuardian}
              kinshipDegree={kinshipDegree}
              setKinshipDegree={setkinshipDegree}
              occupationCodeLegalGuardian={occupationCodeLegalGuardian}
              setOccupationCodeLegalGuardian={setOccupationCodeLegalGuardian}
              rendaLegalGuardian={incomeLegalGuardian}
              setRendaLegalGuardian={setIncomeLegalGuardian}
              clientsLegalGuardianAge={legalGuardianEnteredAge}
              setClientsLegalGuardianAge={setLegalGuardianEnteredAge}
              cpfClient={cpf}
            />
          </Box>
        </>
      ) : (
        ''
      )}

      <Heading
        w="100%"
        m={{
          base: '18px 0 36px 0 !important',
          lg: '36px 0 24px 0 !important'
        }}
        color="#000000"
        fontSize={['1.5rem', '1.5rem', '2rem']}
        fontWeight="700"
        lineHeight={['2rem', '2rem', '3rem']}
        display="flex"
        justifyContent="center"
      >
        Endereço
      </Heading>
      <Box
        w="100%"
        p={{ base: '0', md: '30px' }}
        display="flex"
        justifyContent="center"
      >
        <FormEndereco
          cep={cep}
          setCep={setCep}
          address={address}
          setAddress={setAddress}
          number={number}
          setNumber={setNumber}
          complement={complement}
          setComplement={setComplement}
          district={district}
          setDistrict={setDistrict}
          city={city}
          setCity={setCity}
          uf={uf}
          setUf={setUf}
          submitCep={getAddress}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        m="30px 0 !important"
        w="100%"
        maxW={(widthForRef - 60).toString()}
        gridGap="30px"
      >
        <ConsentTerm />
        <ButtonNavegate
          disable={disableButtonNav}
          actionPrev={() => {
            history.back();
          }}
          actionNext={() => {
            let path = '/condicoes-saude/inicio';

            pushToDataLayer({
              event: 'Lead',
              data: {
                email,
                telefone: phone
              }
            });

            if (getProductType(Number(idProduct)) == 'acidentes_pessoais') {
              path = '/resumo';
            }
            return (window.location.href = `${path}/${getParams(query)}`);
          }}
        />
      </Box>
    </LayoutContent>
  );
};

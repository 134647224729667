import { Button, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';

type ButtonNavegateProps = {
  widthButtons?: string;
  content?: string;
  disable: boolean;
  actionPrev: () => void;
  actionNext: () => void;
};

const buttonColor = '#FF5000';

export const ButtonNavegate = ({
  widthButtons,
  content,
  disable,
  actionPrev,
  actionNext
}: ButtonNavegateProps) => {
  return (
    <>
      <Stack
        maxW="850px"
        w="100% !important"
        display="flex"
        flexDirection={['column', 'row', 'row']}
        justifyContent={['space-between']}
        gridGap="20px"
      >
        <Button
          justifyContent="center"
          w={widthButtons ? ['100%', '100%', widthButtons] : ['100%']}
          bg="#FFFFF"
          m="0 !important"
          h="48px"
          p="13px 27px"
          gap="10px"
          border={`1px solid ${buttonColor || '#FF5000'}`}
          borderRadius="0 !important"
          color={buttonColor || '#FF5000'}
          fontWeight="700"
          fontSize="1rem"
          lineHeight="1.375rem"
          textAlign="center"
          onClick={actionPrev}
          disabled={false}
          _disabled={{
            color: '#D2D2D2',
            border: '1px solid #D2D2D2'
          }}
          _hover={{
            WebkitFilter: ' brightness(60%)',
            transition: 'all 0.4s',
            WebkitTransition: 'all 0.4s'
          }}
          _active={{}}
          _focus={{
            '&:focus': {
              boxShadow: 'none !important'
            }
          }}
        >
          Voltar
        </Button>
        <Button
          justifyContent="center"
          w={widthButtons ? ['100%', '100%', widthButtons] : ['100%']}
          bg={`${buttonColor || '#FF5000'}`}
          m="0 !important"
          h="48px"
          p="13px 27px"
          gap="10px"
          borderRadius="0 !important"
          color="#FFFFFF"
          fontWeight="700"
          fontSize="1rem"
          lineHeight="1.375rem"
          textAlign="center"
          onClick={actionNext}
          disabled={disable}
          _disabled={{
            bg: '#D2D2D2'
          }}
          _hover={
            disable
              ? {}
              : {
                  WebkitFilter: ' brightness(60%)',
                  transition: 'all 0.4s',
                  WebkitTransition: 'all 0.4s'
                }
          }
          cursor={disable ? 'not-allowed' : ''}
          _active={{}}
          _focus={{
            '&:focus': {
              boxShadow: 'none !important'
            }
          }}
        >
          {content ? content : 'Próximo'}
        </Button>
      </Stack>
    </>
  );
};
